import {
  companyBs,
  paragraphs1,
  productName,
  sentence20,
} from '@collective/utils/fixtures';
import cuid from 'cuid';
import dayjs from 'dayjs';

import {
  ApplicationStatus,
  ApplicationType,
  Collective_ProjectType,
  JobWorkPreference,
  OpportunityStatusType,
  ProjectStartDate,
  Public_ProjectType,
  ShortlistPricing,
} from '../../generated';
import { generateClient } from './client';
import { generateOpportunity } from './opportunity';
import { generateOpportunityStatus } from './opportunity-status';
import { generateUser } from './user';

export const generateProject = (
  props?: Partial<Collective_ProjectType>,
  n = 0
): Collective_ProjectType => {
  return {
    id: cuid(),
    name: productName[n],
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    description: paragraphs1[n],
    sumUp: sentence20[n],
    idealStartDate: ProjectStartDate.Asap,
    sharedByMemberId: cuid(),
    isProductLed: false,
    opportunity: generateOpportunity(),
    client: generateClient(undefined, n),
    sharedByAdminUser: generateUser(undefined, n),
    questions: [
      { id: cuid(), name: 'How many POP Mate per day do you need? 🍾' },
      { id: cuid(), name: 'Can you play Sushi Go? 🍣' },
    ],
    proposals: [],
    positions: [],
    isExtension: false,
    extensionName: '',
    shortlistPricing: ShortlistPricing.None,
    forcePayAsYouGo: false,
    applicationType: ApplicationType.Group,
    showWorkExperience: false,
    showEducation: false,
    requireResume: false,
    hideFromJobBoard: true,
    projectTypes: [],
    workPreferences: [JobWorkPreference.Remote],
    source: null,
    publishedAt: new Date().toISOString(),
    ...props,
  };
};

export const generatePublicProject = (
  props?: Partial<Public_ProjectType>,
  n = 0
): Public_ProjectType => {
  return {
    id: cuid(),
    name: productName[n],
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    description: paragraphs1[n],
    sumUp: sentence20[n],
    idealStartDate: ProjectStartDate.Asap,
    client: generateClient(undefined, n),
    sharedByAdminUser: generateUser(undefined, n),
    clientAnonymizedName: companyBs[n],
    applicationType: ApplicationType.Group,
    positions: [],
    ...props,
  };
};

export const generateProjectWithOpportunityStatus = (
  statuses?: OpportunityStatusType[],
  props?: Partial<Collective_ProjectType>,
  n = 0
) =>
  generateProject(
    {
      opportunity: generateOpportunity({
        statuses:
          statuses &&
          statuses.map((status) => generateOpportunityStatus({ status }, n)),
      }),
      ...props,
    },
    n
  );

export const generateProjectWithExpirationDate = (amount: number, n = 0) =>
  generateProject(
    {
      opportunity: generateOpportunity({
        expiresAt: dayjs().add(amount, 'hours').toISOString(),
        applicationStatus:
          amount <= 0
            ? ApplicationStatus.OpportunityUnanswered
            : amount < 24
            ? ApplicationStatus.OpportunityExpireSoon
            : ApplicationStatus.OpportunityExpireLater,
      }),
    },
    n
  );
